body {
  margin: 0;
  font-family: sans-serif;
  /* font-family: 'sans-serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.customRoot .rsc-container {
  top: 0;
  justify-content: center;
  left: 0%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.customRoot .rsc-ts .rsc-bot {
  margin-bottom: 20
}

.customRoot .rsc-content {
  padding-left: 10px;
  padding-right: 10px;
  width: 95%;
}

.customRoot .rsc-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  flex: 0 1 40px;
}

.customRoot .rsc-os-options {
  margin-left: 40px;
}

.customRoot .rsc-os-option {
  display: block;
  margin-bottom: 5px;
}

.customRoot .rsc-ts-bubble {
  word-break: break-word;
  font-size: 17px;
  margin-bottom: 15px;
}

.customRoot .rsc-ts-bubble a {
  color: #2d8ce1;
}

.customRoot .rsc-ts-bubble a:hover {
  color: #247bc9;
  text-decoration: underline;
}

.customRoot .rsc-input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 17px;
  padding-top: 14px;
  padding-left: 55px;
}

.customRoot .rsc-input:focus {
  height: 4em;
}

.customRoot .rsc-ts-user {
  align-items: flex-start;
}

.customRoot .rsc-ts-bot {
  align-items: flex-start;
}

.customRoot #attachIcon {
  left: 0px;
  right: unset !important;
}

.customRoot .rsc-os-option-element {
  text-align: left
}

.customRoot .carousel.carousel-slider .control-arrow {
  top: 75px;
  height: 50px;
}

.dialog {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  padding: 0;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-img {
  width: 100%;
  height: auto;
  margin: 0;
}

.dialog-close {
  cursor: pointer;
  color: #ddd;
  top: 10px;
  right: 10px;
  position: absolute;
  border: 1px solid;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 16px;
}